<template>
  <dragablemodal
    :name="'inviteusermodal'"
    ref="dragablemodal"
    :height="'auto'"
    :width="900"
  >
    <header>{{ T("Web.Generic.Users.Invite") }}</header>
    <b-overlay :show="loading">
      <div class="modals-content">
        <b-card>
          <b-form-group :label="T('Web.Generic.Name')" v-if="showName">
            <b-form-input v-model="userInvite.name" />
          </b-form-group>
          <b-form-group :label="T('Web.Generic.Email')">
            <b-form-input v-model="userInvite.email" />
          </b-form-group>
          <b-form-group
            v-if="hideRole != true"
            :label="T('Web.Generic.Users.Role')"
          >
            <select class="form-control mb-1" v-model="userInvite.role">
              <option v-for="userRole in userRoles" :key="userRole">
                {{ userRole }}
              </option>
            </select>
          </b-form-group>
          <b-form-group
            v-if="hideRole != true"
            :label="T('Web.Generic.Locations.Locations')"
          >
            <location-picker-select
              :value="currentLocation != null ? currentLocation : undefined"
              :multiple="true"
              @input="locationSelected($event)"
              v-if="
                userInvite.role == 'User' ||
                userInvite.role == 'Supplier' ||
                userInvite.role == 'FacilityUser'
              "
            />
            <input
              v-else
              :placeholder="T('Web.Generic.Locations.AllLocations')"
              type="text"
              class="form-control mb-1"
              disabled
            />
          </b-form-group>
        </b-card>
      </div>
    </b-overlay>

    <footer class="modal-footer">
      <div class="w-100">
        <b-button
          variant="primary"
          class="float-right"
          @click="handleSubmit"
          :disabled="!canSubmit || loading"
        >
          {{ T("Web.Generic.Send") }}
        </b-button>
        <b-button
          variant="light"
          class="float-right mr-2"
          @click="handleModalHidden"
        >
          {{ T("Web.Generic.Close") }}
        </b-button>
      </div>
    </footer>
  </dragablemodal>
</template>

<script>
import {
  BModal,
  BButton,
  BAvatar,
  BTable,
  BFormTextarea,
  BCard,
  BRow,
  BFormGroup,
  BFormInput,
  BOverlay,
} from "bootstrap-vue";
import { VBTooltip } from "bootstrap-vue";
import dragablemodal from "@/components/layout/dragablemodal.vue";
import LocationPickerSelect from "@/components/forms/infoCards/LocationPickerSelect.vue";
import authHelper from "@/auth";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BModal,
    BButton,
    BAvatar,
    BTable,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BCard,
    BRow,
    BOverlay,
    VBTooltip,
    dragablemodal,
    LocationPickerSelect,
  },
  props: ["showName", "hideRole"],
  directives: {
    "b-tooltip": VBTooltip,
  },
  created() {},
  data() {
    return {
      userInvite: {
        email: null,
        name: null,
        role: "User",
        locations: [],
      },
      service: null,
      loading: false,
      visible: false,
      resolvePromise: undefined,
      rejectPromise: undefined,
    };
  },
  methods: {
    ...mapActions({
      invite: "accountUsers/inviceUser",
    }),
    locationSelected(event) {
      let newLocationsId = [];
      event.forEach((element) => {
        newLocationsId.push(element.id);
      });
      this.userInvite.locations = newLocationsId;
    },
    async open() {
      this.$refs.dragablemodal.show();
      if (this.currentLocation != null)
        this.userInvite.locations.push(this.currentLocation.id);
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    handleModalHidden() {
      this.userInvite = {
        email: null,
        name: null,
        role: "User",
        locations: [],
      };
      this.$refs.dragablemodal.hide();
    },
    async handleSubmit() {
      if (
        this.userInvite.role == "Admin" ||
        this.userInvite.role == "SystemAdmin"
      )
        this.userInvite.locations = [];
      try {
        this.loading = true;
        await this.invite(this.userInvite);
        this.resolvePromise(true);
        this.handleModalHidden();
      } catch {
        alert("Error");
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters({ currentLocation: "appUser/getCurrentLocationScope" }),
    canSubmit() {
      if (this.userInvite.email == "" || this.userInvite.email == null)
        return false;

      if (
        this.hideRole != true &&
        (this.userInvite.role == "User" ||
          this.userInvite.role == "Supplier") &&
        this.userInvite.locations.length == 0
      )
        return false;

      return true;
    },
    userRoles() {
      let roles = [];
      for (const [key, value] of Object.entries(authHelper.getRoles())) {
        if (value != "Supplier") roles.push(value);
      }
      if (authHelper.isSuperAdmin()) {
        for (const [key, value] of Object.entries(
          authHelper.AssigableRolesForSuperAdmin()
        )) {
          roles.push(value);
        }
      }

      return roles;
    },
  },
};
</script>
