<template>
  <div>
    <confirm-modal ref="confirmModal" />
<!--
    <template v-if="isLoading">
      <div class="d-flex justify-content-center mt-2 mb-2">
        <b-spinner />
      </div>
    </template>
    !-->
    <template>
      <b-card no-body>
        <div class="header p-2 d-flex align-items-center justify-content-end">
          <b-button
            variant="secondary"
            class="btn-dark"
            @click="openInviteUserModel"
          >
            {{ T("Web.Generic.Users.Invite", "Invite users") }}
          </b-button>
          <loader class="loader" ref="loader"></loader>
        </div>
        <b-table
          :fields="fields"
          :items="filteredUserList"
          primary-key="code"
          show-empty
          :empty-text="T('Web.Generic.TableEmpty', 'No items to show')"
        >
          <template #cell(userName)="data">
            <input
              type="text"
              class="form-control"
              v-model="data.item.userName"
              @change="updateAccountUser(data.item)"
            />
          </template>
          <template #cell(email)="data">
            <input
              type="text"
              class="form-control"
              v-model="data.value"
              disabled
            />
          </template>
          <template #cell(phone)="data">
            <input
              type="text"
              class="form-control"
              v-model="data.item.phone"
              @change="updateAccountUser(data.item)"
            />
          </template>
          <template #cell(role)="data">
            <select
              @change="updateAccountUser(data.item)"
              class="form-control"
              v-model="data.item.role"
            >
              <option v-for="userRole in userRoles" :key="userRole">
                {{ userRole }}
              </option>
            </select>
          </template>
          <template #cell(locations)="data">
            <location-picker-select
              :value="undefined"
              :multiple="true"
              :selectedIds="data.item.locationIds"
              @input="locationSelected($event, data.item)"
              v-if="data.item.role == 'User' || data.item.role == 'Supplier' || data.item.role == 'FacilityUser'"
            />
            <span v-else>{{
              T(
                "Web.Generic.Users.AllLocationAccess",
                "Access to all locations"
              )
            }}</span>
          </template>
          <template #cell(actions)="data">
            <b-button
              v-if="!data.item.hasPassword"
              @click="reinviteUser(data.item.email)"
              class="mr-2"
            >
              {{ T("Web.Generic.Users.ReInvite", "Reinvite") }}
            </b-button>
            <b-button
              @click="removeAccountUser(data.item)"
              variant="danger"
              class="mr-2"
            >
              {{ T("Web.Generic.Remove") }}
            </b-button>
          </template>
        </b-table>
      </b-card>
    </template>
    <invite-user-model ref="inviteUserModel"></invite-user-model>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import loader from "@/components/layout/loader.vue";
import authHelper from "@/auth";
import InviteUserModel from "@/components/modals/auth/InviteUserModel.vue";
import LocationPickerSelect from "@/components/forms/infoCards/LocationPickerSelect.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

import {
  BSpinner,
  BCard,
  BButton,
  BRow,
  BCol,
  BTabs,
  BTab,
  BTable,
  BCardTitle,
} from "bootstrap-vue";
export default {
  components: {
    BSpinner,
    BCard,
    BButton,
    BRow,
    BCol,
    BTabs,
    BTab,
    BTable,
    loader,
    InviteUserModel,
    LocationPickerSelect,
    BCardTitle,
    ConfirmModal,
  },
  async created() {
    await this.fetchAccountUsers();
  },
  data() {
    return {
      service: null,
      active: "collectors",
      activeLoading: false,
      type: "border",
      activeLocation: undefined,
      fields: [
        {
          key: "userName",
          label: this.T("Web.Generic.Name"),
          sortable: true,
        },
        {
          key: "email",
          label: this.T("Web.Generic.Email"),
          sortable: true,
        },
        {
          key: "phone",
          label: this.T("Web.Generic.Phone"),
          sortable: true,
        },
        {
          key: "role",
          label: this.T("Web.Generic.Users.Role", "Role"),
          sortable: true,
        },
        {
          key: "locations",
          label: this.T("Web.Generic.Locations.Locations"),
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      locations: "locations/list",
      accountUsers: "accountUsers/list",
      loadingLocations: "locationsOld/isLoading",
      loadingUsers: "accountUsers/isLoading",
      currentLocation: "appUser/getCurrentLocationScope",
    }),
    userRoles() {
      let roles = [];
      for (const [key, value] of Object.entries(authHelper.getRoles())) {
        if (value != "Supplier") roles.push(value);
      }
      if (authHelper.isSuperAdmin()) {
        for (const [key, value] of Object.entries(
          authHelper.AssigableRolesForSuperAdmin()
        )) {
          roles.push(value);
        }
      }

      return roles;
    },
    isLoading() {
      return this.loadingLocations || this.loadingUsers;
    },
    filteredUserList() {
      if (this.currentLocation == undefined) return this.accountUsers;
      return this.accountUsers.filter(
        (u) =>
          u.locationIds.includes(this.currentLocation.id) ||
          u.role == "Admin" ||
          u.role == "SystemAdmin"
      );
    },
  },
  methods: {
    ...mapActions({
      fetchLocations: "locationsOld/fetchList",
      fetchAccountUsers: "accountUsers/fetchList",
      inviteUser: "accountUsers/reinviteUser",
      putAccountUsers: "accountUsers/put",
      deleteAccountUsers: "accountUsers/delete",
    }),
    async removeAccountUser(accountuser) {
      if (
        !(await this.$refs.confirmModal.confirm(
          this.T(
            "Web.Generic.Users.ConfirmRemove",
            "Are you sure you want to remove this user?"
          )
        ))
      )
        return;

      this.$refs.loader.loading();
      await this.deleteAccountUsers({ resourceId: accountuser.id });
      await this.fetchAccountUsers();

      this.$refs.loader.loadCompelete();
    },
    async locationSelected(event, accountuser) {
      let newLocationsId = [];
      event.forEach((element) => {
        newLocationsId.push(element.id);
      });

      accountuser.locationIds = newLocationsId;

      await this.updateAccountUser(accountuser);
    },
    async reinviteUser(email) {
      this.$refs.loader.loading();
      this.inviteUser({ email: email });
      this.$refs.loader.loadCompelete();
    },
    async openInviteUserModel() {
      let result = await this.$refs.inviteUserModel.open();
      if (result) await this.fetchAccountUsers();
    },
    async updateAccountUser(accountuser) {
      this.$refs.loader.loading();
      await this.putAccountUsers({ data: accountuser });
      this.$refs.loader.loadCompelete();
    },
  },
};
</script>

<style lang="scss" scoped>
.topbar {
  position: relative;
  .loader {
    position: absolute;
    top: -15px;
    right: 0;
  }
}
.row {
  align-items: end;
}
.rowitem {
  &.offset-top {
    &:first-of-type {
      margin-top: 0;
    }
    margin-top: 10px;
  }
}
.offset-top {
  margin-top: 20px;
}
</style>